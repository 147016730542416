import type { DynamicSettingsProps } from '@melio/i18n-tools';
import { Provider as MessagingProvider } from '@melio/in-app-marketing';
import { IconKey, Image, PennyConfig, PennyProvider, ThemeIllustrationType, ThemeOptions } from '@melio/penny';
import { ApiProvider, ApiProviderProps, PartnerName } from '@melio/platform-api';
import { EngagementProvider } from '@melio/platform-engagement';
import { AsyncLocalizationProvider, LocalizationProviderProps } from '@melio/platform-i18n';
import { PermissionsProvider } from '@melio/platform-permissions';
import { LazyAnimation, SystemMessageProvider } from '@melio/platform-utils';
import { getDeviceDataHeader } from '@melio/risk-data-collection';
import { useCallback, useMemo } from 'react';

import { ConfigProvider, ConfigProviderProps } from './ConfigProvider';
import { FeedbackProvider } from './FeedbackProvider';
import { TBTProvider } from './TBTProvider';
import { DSProps, FeedbackProviderProps, Illustration } from './types';

export type MelioProviderProps = Omit<DSProps, 'config'> &
  LocalizationProviderProps &
  ApiProviderProps &
  DynamicSettingsProps &
  ConfigProviderProps &
  FeedbackProviderProps & { partnerName?: PartnerName; permissionsEnabled?: boolean; pennyConfig?: PennyConfig };

export function toDSTheme(theme: DSProps['theme']): ThemeOptions {
  const illustrations = Object.fromEntries(
    (Object.entries(theme.illustrations ?? {}) as [ThemeIllustrationType, Illustration][]).map<
      [ThemeIllustrationType, React.ComponentType]
    >(([key, value]) => [
      key,
      () =>
        value.type === 'animation' ? (
          <LazyAnimation id="expired" src={value.src} width="100%" height="100%" />
        ) : (
          <Image src={value.src} alt={key} height="auto" width="auto" />
        ),
    ])
  );

  const icons = Object.fromEntries(
    (Object.entries(theme.icons ?? {}) as [IconKey, string][]).map<[IconKey, React.ComponentType]>(
      ([key, value]: [IconKey, string]) => [key, () => <Image src={value} alt={key} height="auto" width="auto" />]
    )
  );

  return {
    ...theme,
    illustrations,
    icons,
    logos: {
      light: () => (theme.logos?.light ? <Image key={theme.logos?.light} src={theme.logos?.light} alt="logo" /> : null),
      dark: () => (theme.logos?.dark ? <Image key={theme.logos?.dark} src={theme.logos?.dark} alt="logo" /> : null),
    },
  };
}

const getPartnerSessionHeaders = () => {
  const sessionData = sessionStorage.getItem('sessionData');
  return { 'x-partner-session-data': sessionData || '' };
};

export const MelioProvider: React.FC<MelioProviderProps> = ({
  config,
  accessToken,
  onTokenExpired,
  feedbackWidgetProps,
  translationsSrc,
  dynamicSettings,
  locale,
  permissionsEnabled,
  children,
  partnerName,
  pennyConfig,
  ...rest
}) => {
  const isDefaultTZ = config?.settings?.defaultTZ?.enabled ?? false;
  const getEnrichedRequestHeaders = useCallback(
    () =>
      Promise.resolve({
        'x-site-context': partnerName ?? '',
        ...getDeviceDataHeader(),
        ...getPartnerSessionHeaders(),
      }).catch(() => ({})),
    [partnerName]
  );

  const shouldShowContentKeys = dynamicSettings?.shouldShowContentKeys ?? false;
  const { theme, ...restDSProviderProps } = rest;
  const dsTheme = useMemo(() => toDSTheme(theme), [theme]);
  return (
    <ConfigProvider config={config}>
      <PennyProvider {...restDSProviderProps} theme={dsTheme} config={pennyConfig}>
        <ApiProvider
          apiServer={config?.server?.apiServer}
          accessToken={accessToken}
          onTokenExpired={onTokenExpired}
          getRequestHeaders={getEnrichedRequestHeaders}
          queryClient={rest?.queryClient}
        >
          <AsyncLocalizationProvider
            translationsSrc={translationsSrc}
            locale={locale}
            shouldShowContentKeys={shouldShowContentKeys}
            isDefaultTZ={isDefaultTZ}
          >
            <PermissionsProvider enabled={permissionsEnabled} accessToken={accessToken}>
              <FeedbackProvider feedbackWidgetProps={feedbackWidgetProps}>
                <TBTProvider>
                  <EngagementProvider accessToken={accessToken} config={{ production: config?.production }}>
                    <MessagingProvider>
                      <SystemMessageProvider>{children}</SystemMessageProvider>
                    </MessagingProvider>
                  </EngagementProvider>
                </TBTProvider>
              </FeedbackProvider>
            </PermissionsProvider>
          </AsyncLocalizationProvider>
        </ApiProvider>
      </PennyProvider>
    </ConfigProvider>
  );
};
