const BASE = '/accountants';
export const routes = {
  BASE,
  DASHBOARD: `${BASE}/dashboard`,
  INVITATION_SENT: `${BASE}/client/invitation-sent`,
  CLIENT_CREATED: `${BASE}/client/new/success`,
  CREATION_OPTIONS: `${BASE}/client/creation-options`,
  ADD_NEW_CLIENT: `${BASE}/client/new/empty`,
  INVITE_EXISTING_CLIENT: `${BASE}/client/invite`,
  getClientAssignPlanRoute: (accountingClientId: string) => `${BASE}/client/${accountingClientId}/assign-plan`,
  getClientManageBillingRoute: (accountingClientId: string) => `${BASE}/client/${accountingClientId}/billing`,
  getClientAssignPlanSuccessRoute: (accountingClientId: string) =>
    `${BASE}/client/${accountingClientId}/assign-plan/success`,
  CLIENT_ACCOUNTING_SOFTWARE_REDIRECT: `${BASE}/client/accounting-software-redirect`,
  BILLING_FEE_FUNDING_SOURCE_SELECTION: `${BASE}/billing-fee/select-payment-method`,
};
