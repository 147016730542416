/* eslint-disable max-lines */
import { CSSObject } from '@emotion/styled';
import {
  BrandSymbolKey,
  IconKey,
  LayoutProps,
  PennyProviderProps,
  ThemeIllustrationType,
  ThemeOptions,
} from '@melio/penny';
import {
  AccountingPlatformSlug,
  Card,
  DeliveryMethodByPayor,
  DeliveryMethodType,
  FundingSource,
  RoleUniqueNames,
  SupportedDeliveryMethodTypeOption,
} from '@melio/platform-api';
import { SpaceApi } from '@usersnap/browser';
import { CSSProperties } from 'react';

import type { PathsToProperty, PathsWithEnabled } from './typeUtils';

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export const accountIntegrationTypes = ['deposits', 'plaid'] as const;

export type AccountIntegrationType = (typeof accountIntegrationTypes)[number];

const cardBrands = ['visa', 'mastercard', 'american-express', 'diners-club', 'discover', 'unknown'] as const;
export type CardBrand = (typeof cardBrands)[number];

type ManagedAccount = 'managed-account';
type ManagedAccountDeliveryTypes = ManagedAccount | `${ManagedAccount}:check` | `${ManagedAccount}:ach`;

type DeliveryMethodMemoBlacklist = DeliveryMethodType | ManagedAccountDeliveryTypes;

export const CONFIG_OVERRIDES_KEY = 'config-overrides';

export const TrustedPartners = {
  Evolve: 'Evolve',
  Mastercard: 'Mastercard',
  Quickbooks: 'Quickbooks',
  Diners: 'Diners',
  Visa: 'Visa',
  Discover: 'Discover',
  Amex: 'Amex',
} as const;

export type TrustedPartners = (typeof TrustedPartners)[keyof typeof TrustedPartners];

export enum PaymentSchedulingPreference {
  ByScheduleDate = 'byScheduleDate',
  ByDeliveryDate = 'byDeliveryDate',
}

export enum ContextualOnboardingButtons {
  QuickBooksOnline = 'QuickBooksOnline',
  GmailSync = 'GmailSync',
  FirstPayment = 'FirstPayment',
}

export type UnilateralRequest = {
  announceFees: boolean;
};
export type FileType = 'jpg' | 'png' | 'pdf';

export type FundingSourceGroupName = FundingSource['type'] | Card['type'];
export type FundingSourceCardPolicy = {
  fundingSourceCard?: {
    showCreatedBy?: boolean;
  };
};
export type FundingSourcePolicy = {
  [key in FundingSource['type'] | Card['type']]?: {
    order?: number;
    fundingSourceGroup?: FundingSourceGroupName;
    read?: boolean;
    write?: boolean;
    creationHosting?: 'app' | 'iframe';
  };
};

export enum VendorDirectory {
  Fiserv = 'Fiserv',
}

type EventDestination = 'Braze';

export type LinkConstants = {
  'app.settings.SupportSection.accordion.businessBills.links.link1.href': string;
  'app.settings.SupportSection.accordion.addSparkCard.links.link1.href': string;
  'app.settings.SupportSection.accordion.syncQuickBooks.links.link1.href': string;
  'app.settings.SupportSection.accordion.addDebitCard.links.link1.href': string;
  'app.settings.SupportSection.accordion.verifyMicroDeposits.links.link1.href': string;
  'app.settings.SupportSection.accordion.addBankAccount.links.link1.href': string;
  'app.settings.SupportSection.accordion.addBill.links.link1.href': string;
  'app.settings.SupportSection.accordion.payBill.links.link1.href': string;
  'app.settings.SupportSection.accordion.deliveryTimelines.links.link1.href': string;
  'app.settings.SupportSection.accordion.vendors.links.link1.href': string;
  'app.settings.SupportSection.accordion.cancelPayment.links.link1.href': string;
  'app.settings.SupportSection.accordion.security.links.link1.href': string;
  'app.settings.SupportSection.accordion.addBillClone.links.link1.href': string;
  'app.settings.SupportSection.accordion.addBillClone.links.link2.href': string;
  'app.settings.SupportSection.accordion.addBillClone.links.link3.href': string;
  'widgets.footer.termsOfServiceLink.href': string;
  'widgets.footer.privacyPolicy.href': string;
  'widgets.learnAboutRates.href': string;
  'widgets.companySettings.header.sectionBanner.linkHref': string;
  'activities.repaymentTerms.screens.repaymentTerms.TermsAndConditions.creditKey': string;
  'widgets.fundingSources.selectFundingSourceCard.payWithFinancing.ineligible.reason.vendor-ineligible.learnMore.href': string;
  'activities.financingApplication.screens.applicationForm.title.learnMore': string;
  'activities.checkProtectionModal.securing.link': string;
  'creditKey.legal.privacyPolicy': string;
  'creditKey.legal.termsOfService': string;
  'fx.legal.termsAndConditions': string;
  helpCenter: string;
  submitSupportRequest: string;
  backButtonUrl: string;
  backButtonUrlFallback: string;
  portalLink: string;
  failedToCollectSupport: string;
  financingMoreDetails: string;
  unilateralDeliveryLearnMore: string;
  undepositedCheckLearnMore: string;
  contactCreditKey: string;
  resolveFailedPayment: string;
  contactMelioSupport: string;
  quickBooksDesktopWebConnectorGuide: string;
  verifyBankAccountWithMicroDeposit: string;
  'activities.multiAccountBanner.cta.href': string;
};

type FeedbackTrigger = {
  enabled: boolean;
  userSnapProjectId: string;
};

export type DeliveryMethodTypeOptionDisabledReasonsMap = {
  [key in SupportedDeliveryMethodTypeOption['type']]: SupportedDeliveryMethodTypeOption['reason'][];
};

export enum VendorDetailsFormType {
  Modal = 'Modal',
  Drawer = 'Drawer',
}

export enum BillsEmailInboxDashboardView {
  Link = 'link',
  IconAndLink = 'icon-and-link',
}

export type SubscriptionsDataConfig = {
  expectOrgsToBeSubscribed?: boolean;
  isGracePeriodSupported?: boolean;
  claimSuggestedPlan?: boolean;
  links: {
    seeAllFeatures?: string;
    learnMore?: {
      smb?: string;
      accountingClients?: string;
      accountingFirmsV1?: string;
      accountingFirmsV2?: string;
    };
  };
  isPaymentFeeFailedGracePeriodEnabled?: boolean;
};

export type PaymentRequestUrlDecodedPayload = {
  vendorDetails?: {
    name?: string;
  };
  paymentRequestDetails?: {
    ref?: string;
  };
};

export type Illustration = {
  src: string;
  type: 'animation' | 'image';
};

export type Theme = RecursivePartial<{
  [K in keyof ThemeOptions]: K extends 'logos'
    ? Record<'light' | 'dark', string>
    : K extends 'illustrations'
    ? Record<ThemeIllustrationType, Illustration>
    : K extends 'icons'
    ? Record<IconKey, string>
    : ThemeOptions[K];
}>;

export type DSProps = {
  [K in keyof PennyProviderProps]: K extends 'theme' ? Theme : PennyProviderProps[K];
};

type RegexPattern = {
  pattern: string;
  flags?: string;
};

export type SettingsGroupEnum = 'company' | 'payments' | 'ar' | 'support';

export type SettingsCardIdEnum =
  | 'profile-settings'
  | 'company-settings'
  | 'collaborators-setting'
  | 'workflows-setting'
  | 'notification-preferences'
  | 'accounting-software-sync'
  | 'payment-methods-setting'
  | 'receiving-methods-settings'
  | 'billing-setting'
  | 'support-setting'
  | 'subscription-plans-setting'
  | 'invoice-items'
  | 'invoice-email-notifications'
  | 'invoice-settings'
  | 'tax-and-reports'
  | 'billing-statements';

export type SettingsGroupConfig = {
  type: SettingsGroupEnum;
  items: SettingsCardIdEnum[];
};

export type RequestCallbackNotes = 'callFrom' | 'callRecorded' | 'emailVerification' | 'didntGetEmailVerification';

export type MelioConfig = {
  tabTitle: string;
  translationsSrc?: string;
  server: {
    apiServer: string;
  };
  settings: {
    migrationUserTour: {
      isEnabled: boolean;
      shouldFinishOnSessionEnd?: boolean;
      modalImage: string;
      stepsImages: {
        step1: string;
        step2: string;
        step3: string;
        step4: string;
        step5: string;
        step6: string;
      };
    };
    settings: {
      options: SettingsGroupConfig[];
    };
    payment: {
      newPaymentFlow?: {
        enabled: boolean;
        flagName?: string;
      };
      isConfirmationNumberEnabled: boolean;
      supportedFundingSourceTypes: Record<FundingSource['type'], boolean>;
      loadingStates: {
        vendor: Array<'vendor' | 'amount' | 'fs' | 'dm'>;
        amount: Array<'vendor' | 'amount' | 'fs' | 'dm'>;
        fs: Array<'vendor' | 'amount' | 'fs' | 'dm'>;
        dm: Array<'vendor' | 'amount' | 'fs' | 'dm'>;
        bill: Array<'vendor' | 'amount' | 'fs' | 'dm'>;
        recurrenceType: Array<'vendor' | 'amount' | 'fs' | 'dm'>;
        date: Array<'vendor' | 'amount' | 'fs' | 'dm'>;
      };
      scheduling: {
        initialFormCollapsedState: 'full' | 'partial';
        scheduleBy: PaymentSchedulingPreference;
        showEarlierApprovalDate: boolean;
        maxDeliveryDateInDays: number;
        showFuturePaymentBanner: boolean;
        disableEditDeliveryDateForCards: boolean;
        minAmountUsd: number;
        maxAmountUsd: number;
        recurringEndDateRestrictionYears?: number;
        removeIneligibleFundingSources: boolean;
        disableFundingSourceTypeToggleIfNoOptions: boolean;
        fundingSourceTypeSelectionEnabled: boolean;
      };
      editing: {
        defaultToManagedAccountPreferredDeliveryMethod: boolean;
        shouldCheckFundingSourceEntitlements: boolean;
        disableEditDeliveryMethod: boolean;
        fundingSourceTypeSelectionEnabledOnEdit: boolean;
      };
      scheduled: {
        showZeroFees: boolean;
        showEstimatedDeliveryDateBanner: boolean;
      };
      showCreatedByIndication: boolean;
      memoToVendor: {
        maxLength: number;
        deliveryMethodBlackList: DeliveryMethodMemoBlacklist[];
        allowSendingEmailToVendorWhenAddingMemo: boolean;
        memoRegex: RegexPattern;
      };
      noteToSelf: {
        noteRegex: RegexPattern;
      };
      allowNotifyingVendor: boolean;
      showBillForJustPayPayments: boolean;
      flowOfFundsIndicator: {
        enabled: boolean;
        showDateRange: boolean;
      };
    };
    batchPayments: {
      showDeliverySpeedColumn: boolean;
      showVendorVerifiedDeliveryMethodTooltip: boolean;
      usePreDefinedlDeliveryMethodList: boolean;
      backgroundColor: LayoutProps['backgroundColor'];
      showDebitSameDateIndication: boolean;
      showZeroFees: boolean;
      showFeesBreakdown: boolean;
      useMinimalHeaderDesign: boolean;
      showLateIndicator: boolean;
      headerVariant?: 'light' | 'dark';
      showFeesTooltipTitle?: boolean;
      showRelativeDates?: boolean;
      closeButtonOnLeft?: boolean;
      showVendorDetails?: boolean;
      columnActions: {
        deliveryDate: {
          disabled: Array<'dueDate'>;
        };
      };
    };
    fundingSourcePolicy?: FundingSourcePolicy & FundingSourceCardPolicy;
    uploadBill?: {
      shouldShowMultipleBillUploadIndication: boolean;
    };
    entitledFundingSourceCard?: boolean;
    icons: {
      fundingSource: {
        bank: IconKey | BrandSymbolKey;
      };
      deliveryMethod: {
        virtualCard: BrandSymbolKey;
      };
    };
    billsEmailInboxDashboardView: BillsEmailInboxDashboardView;
    supportRequestTimeoutEnable: boolean;
    assets: { [key: string]: string };
    collectMissingCompanyLegalInfoFields: boolean;
    partnerDisplayName: string;
    partnerProductName: string;
    expiredSessionTime: number;
    bankIntegrations?: AccountIntegrationType[];
    supportEmail: string;
    preSeason1099BannerLearnMoreLink: string;
    preSeason1099BannerPendingW9FormsLearnMoreLink: string;
    termsOfServiceUrl: string;
    learnMoreAccountVerificationURL: string;
    mccLearnMoreUrl: string;
    privacyPolicyUrl: string;
    goodsReceivedAmountThreshold: number;
    requireInvoiceForBillsAmountThreshold: number;
    helpCenterUrl: string;
    howContactToSupportArticleUrl: string;
    registerToMelioGetPaid: string;
    fileSizeLimit: number;
    fileAllowedFormats: FileType[];
    tokenProvider: 'tabapay' | 'tbt' | 'carat';
    eoyCheck:
      | { enabled: false }
      | {
          enabled: true;
          flagName: 'us-holidays-checks-partners';
          promoteFastCheck: boolean;
          link: string;
        };
    trustedPartners: TrustedPartners[];
    unilateralRequest: UnilateralRequest;
    payDashboardBillsTabTourEnabled: boolean;
    payDashboardSearchLabelDisabled: boolean;
    newPayExperience: {
      firstTimeExperience: {
        assetType: 'image' | 'video';
        imageUrl: string;
        mobileImageUrl: string;
        videoLink: string;
      };
    };
    accountingFirmDashboard: {
      emptyStateAsset: string;
    };
    pciCompliance: {
      link: string;
    };
    newBillExperience: {
      isRecurringEnabled: boolean;
      isRecurringPaymentImprovementsEnabled: boolean;
      shouldShowIconInBillsTabCta: boolean;
      invoiceNumberFormatRegexp?: RegexPattern;
      noteToSelfFormatRegexp?: RegexPattern;
    };
    isEmbeddedExperience: boolean;
    embeddedExperienceInsideScroll?: boolean;
    showFooterInEmbeddedExperience?: boolean;
    hideHeaderNavigation?: boolean;
    showRetryButtonOnFieldValidation?: boolean;
    accountingSoftwareSuccessReturnUrl?: string;
    accountingSoftwareErrorReturnUrl?: string;
    isPaymentApprovalLimitEnabled: boolean;
    showApproveActionButton: boolean;
    isDeclinePaymentReasonEnabled: boolean;
    isVendorLimitedToSingleVendorGroup: {
      enabled: boolean;
    };
    collaborator: {
      isAddCollaboratorEnabled: boolean;
      isChangeCollaboratorPermissionLevelEnabled: boolean;
      showRoleTransferDisclaimer: boolean;
      rolesOrderMapping: Partial<Record<RoleUniqueNames, number>>;
    };
    isEntitlementsEnabled: boolean;
    paymentLateApproval: { debitDatePassedIndicationEnabled: boolean; debitDateTodayIndicationEnabled: boolean };
    isMultiColoredAvatarsEnabled: boolean;
    isUnilateralDeliveryMethodSupported: boolean;
    isPaymentTimelineEnabled: boolean;
    virtualCardLearnMoreLink: string;
    contractorLearnMoreLink: string;
    contractorsPendingW9Link: string;
    contractorsInvalidTINLink: string;
    zenworkPricingPolicies: string;
    tax1099LearnMoreLink: string;
    termAndConditionsLink: string;
    showDemoRequestIcon: boolean;
    contextualOnboarding: {
      buttons: ContextualOnboardingButtons[];
    };
    vendor: {
      collectedDetails: 'extended' | 'minimal';
      requiredDirectoryFields: string[];
      vendorSelect: {
        createVendor: {
          showVendorDetailsForm: boolean;
          vendorDetailsFormType: VendorDetailsFormType;
        };
      };
      createVendor: {
        shouldSwitchToExtendedFormOnApiErrors: boolean;
        hasSkipDeliveryMethodButton: boolean;
        companyNameFormatRegexp?: RegexPattern;
        uniqueNameFormatRegexp?: RegexPattern;
        companyNameMaxLength?: number;
        companyNameMinLength?: number;
        shouldShowBankDetailsFields: boolean;
        shouldShowAddressDetailsBanner: boolean;
        shouldCollectAccountNumberForUnmanagedVendor?: boolean;
      };
      forms: {
        shouldUseZipCodeMask: boolean;
        shouldUseTooltipsForManagedVendorForm: boolean;
        shouldUseSearchVendorSectionLabel: boolean;
        shouldRequireEmail: boolean;
        shouldDisableEmailEdit: boolean;
        shouldCollectCountry: boolean;
      };
      warnEditingVendorWithFuturePayments: boolean;
    };
    virtualCardExpiryPeriodInDays: number;
    deliveryMethodTypeOrder: Record<DeliveryMethodByPayor['type'], number>;
    deliveryMethodTypeOptionDisabledReasons: DeliveryMethodTypeOptionDisabledReasonsMap;
    promoteAchDeliveryMethod: boolean;
    newConsentScreen: boolean;
    syncProgressIndicationEnabled: boolean;
    supportPhoneNumber: string;
    vex: {
      zdSupportFormUrl: string;
      vendorsContactEmail: string;
      persistentFastOptOutArticleUrl: string;
      unilateral: {
        isEmailLoginEnabled: boolean;
        shouldSuggestJoinMelio: boolean;
        isNewUnilateral: boolean;
        suvcInformationLink: string;
      };
    };
    showCheckMarkIconForManagedVendor: boolean;
    singlePaymentStepLayout: {
      backgroundColor: LayoutProps['backgroundColor'];
    };
    subscriptions?: SubscriptionsDataConfig;
    InternationalFx: {
      enabled: boolean;
    };
    Brand?: {
      color?: {
        flagName?: 'organization-brand-color-selection';
        enabled?: boolean;
      };
      logo?: {
        enabled?: boolean;
      };
    };
    PlatformApprovalWorkflowsV2: {
      flagName: 'platform-approval-workflows-v2';
      enabled?: boolean;
    };
    MarkAsPaid: {
      flagName?: 'mark-as-paid';
      enabled?: boolean;
    };
    ApprovalWorkflowPendingApprovers: {
      flagName?: 'platform-approval-workflow-pending-approvers';
      enabled?: boolean;
    };
    EBillLegalDisclaimer: {
      flagName?: 'ebills-legal-disclaimers';
      enabled?: boolean;
    };
    VendorGroups: {
      flagName?: 'platform-vendor-groups';
      enabled?: boolean;
    };
    Todos: {
      icon: IconKey;
    };
    UploadVendorsCsv: {
      flagName?: 'platform-upload-vendors-csv';
      enabled?: boolean;
    };
    PreviewInvoice: {
      flagName?: 'platform-preview-invoice';
      enabled?: boolean;
    };
    PayDashboardSaveSort: {
      flagName?: 'pay-dashboard-save-sort';
      enabled?: boolean;
    };
    PaymentsTabFilters: {
      flagName?: 'platform-payments-filters';
      enabled?: boolean;
    };
    PaymentsTabFiltersPhase2: {
      flagName?: 'platform-payments-filters-phase-2';
      enabled?: boolean;
    };
    BillsTabFilters: {
      flagName?: 'platform-bills-filters';
      enabled?: boolean;
    };
    VendorGroupsSaveFilter: {
      enabled?: boolean;
    };
    ShowTotalPaymentIncludingFees: {
      enabled: boolean;
    };
    PayDashboardTimelineDisableDates: {
      flagName?: 'pay-dashboard-timeline-disable-dates';
      enabled?: boolean;
    };
    InvalidVendorBankAccountModal: {
      flagName?: 'platform-invalid-vendor-bank-account-modal';
      enabled?: boolean;
    };
    File1099SettingsTaxAndReports: {
      flagName?: 'platform-feature-1099-tax-and-reports-settings';
      enabled: boolean;
      assets?: {
        zenworkSyncWithTax1099Banner: string;
        zenworkTax1099Logo: string;
        zenwork1099SeasonBanner: string;
      };
    };
    Import1099Contractors: {
      flagName?: '1099-import-contractors';
      enabled: boolean;
    };
    File1099PreSeasonBanner: {
      flagName?: '1099-pre-season-banners-dashboard';
      enabled: boolean;
    };
    File1099SeasonBanner: {
      flagName?: '1099-season-banner-dashboard';
      enabled: boolean;
    };
    EbillSubscriptionCancelPaper: {
      flagName?: 'ebill-subscription-cancel-paper';
      enabled: boolean;
    };
    OnboardingModalEnabled: {
      flagName?: 'platform-feature-onboarding-modal-enabled';
      enabled: boolean;
    };
    MigratedUserTourTripEnabled: {
      flagName?: 'new-tour-tip-for-migrated-users';
      enabled: boolean;
    };
    BillingStatementsTab: {
      flagName?: 'billing-statements-tab';
      enabled: boolean;
    };
    accountingSoftware: {
      isConnectConfirmationEnabled: boolean;
    };
    guestPaymentFlow: {
      shouldGenerateDeepLinkToPaymentRequestPayDashboard: boolean;
    };
    unmaskVendorAccountNumber?: boolean;
    decryptDeliveryMethodAccountNumber?: boolean;
    PayDashboardSearchOnKeyPress: {
      flagName?: 'pay-dashboard-search-on-key-press';
      enabled?: boolean;
    };
    ShowLineItemsPremiumPill: {
      flagName?: 'platform-feature-show-line-items-premium-pill';
      enabled: boolean;
    };
    ScannedInvoiceDisclaimer: {
      enabled: boolean;
    };
    ZenworkW9Form: {
      flagName?: 'platform-feature-zenwork-w-9-form';
      enabled: boolean;
    };
    BlockInternationalPaymentsForOrganizations: {
      flagName?: 'block-international-payments-for-organizations';
      enabled?: boolean;
    };
    BlockRPPSPaymentsForOrganizations: {
      flagName?: 'block-rpps-payments-for-organizations';
      enabled?: boolean;
    };
    OutdatedSessionModal: {
      flagName?: 'platform-outdated-session-modal';
      enabled: boolean;
    };
    UseOAuthPopup: {
      flagName?: 'platform-use-oauth-popup';
      enabled: boolean;
    };
    BillingFeeSettingsForExternalAccountants: {
      flagName: 'enable-billing-fee-settings-for-external-accountant';
      enabled: boolean;
    };
    PaymentNoteToSelf: {
      flagName?: 'platform-payment-note-to-self';
      enabled?: boolean;
    };
    defaultTZ: {
      enabled: boolean;
    };
    PaymentsTabStatusCellRefundDescription: {
      enabled: boolean;
    };
    verifyFundingSourceOnlyByCreator: {
      enabled: boolean;
    };
    hideBusinessTypeInCompanyInfo: {
      enabled: boolean;
    };
    showInsufficientCreditBanner: {
      flagName: 'platform-show-insufficient-credit-card-banner';
      enabled: boolean;
    };
    showNoCreditCardBanner: {
      flagName: 'platform-show-no-credit-card-banner';
      enabled: boolean;
    };
    MtlKycUpliftCompanyDetails: {
      flagName?: 'platform-mtl-kyc-uplift-company-details';
      enabled: boolean;
    };
    PlatformMultipleVendorsWithTheSameName: {
      flagName?: 'platform-multiple-vendors-with-the-same-name';
      enabled: boolean;
    };
    ShowVendorDetailsModalForVisa: {
      enabled: boolean;
    };
    VendorDeliveryMethodCombinedExperience: {
      enabled: boolean;
      flagName?: string;
    };
    NewPaymentFlowAddCardDrawer: {
      enabled: boolean;
      flagName?: string;
    };
    BatchPaymentsSubmitErrors: {
      flagName?: 'platform-batch-payment-submit-errors';
      enabled: boolean;
    };
    PromoteAccountingSoftwareSync: {
      flagName?: 'platform-promote-accounting-software-sync';
      enabled: boolean;
      accountingSoftwareSlug?: AccountingPlatformSlug;
    };
    PromoteCardFundingSource: {
      flagName?: 'platform-promote-card-funding-source';
      enabled: boolean;
    };
    FofImprovements: {
      flagName?: 'fof-improvements';
      enabled: boolean;
    };
    EnableInternationalUSDWise: {
      flagName: 'enable-international-usd-wise';
      enabled: boolean;
    };
    ExternalAccountantMandatoryPlanRestriction: {
      flagName: 'enable-external-accountant-mandatory-plan-restriction';
      enabled: boolean;
    };
    DeliveryMethodPublishNewEvents: {
      flagName: 'publish-delivery-method-new-events';
      enabled: boolean;
    };
    EnableRequestACallback: {
      flagName: 'enable-request-a-callback';
      enabled: boolean;
    };
    PaypalBalanceDeliveryMethodEnabled: {
      flagName: 'paypal-balance-delivery-method';
      enabled: boolean;
    };
    NewBusinessesSearch: {
      flagName: 'platform-new-businesses-search';
      enabled: boolean;
    };
    DisplayCancelPaymentsBannerInCancelFlowForClients: {
      flagName: 'display-cancel-payments-banner-in-cancel-flow-for-clients';
      enabled: boolean;
    };
    IsOrgOwesUsMoneyBannerEnabled: {
      flagName: 'is-org-owes-us-money-banner-enabled';
      enabled: boolean;
    };
    MultiAccountBanner: {
      flagName?: 'multi-account-banner';
      enabled: boolean;
    };
    IsArFeeAbsorptionSettingsEnabled: {
      flagName: 'is-ar-fee-absorption-settings-enabled';
      enabled: boolean;
    };
    DuplicateVendors: {
      flagName: 'sync-deactivated-for-register';
      enabled: boolean;
    };
    CheckAllVendorsWhenMatching: {
      flagName: 'check-all-vendors-when-matching';
      enabled: boolean;
    };
    AutoMatchHighConfidenceVendors: {
      flagName: 'auto-match-high-confidence-vendors';
      enabled: boolean;
    };
    TermsAndConditions: {
      flagName: 'sign-terms-and-conditions';
      enabled: boolean;
    };
    RedirectOnTermsAndConditionsScreenReject: {
      enabled: boolean;
    };
    isUploadBillsEnabled: boolean;
    isBillsInboxEnabled: boolean;
  };
  services: {
    zendeskKey: string;
    zendeskWorkflow: string;
    googleClientId: string;
    smartystreets: {
      apiKey: string;
    };
    zenwork?: {
      clientId: string;
      melioRedirectUrl: string;
      identityServiceUrl: string;
      formsDashboardUrl: string;
    };
    googleMaps: {
      apiKey: string;
    };
    plaid: {
      env: string;
      apiKey: string;
    };
    tabapay: {
      card: TabapayIframe;
      credit?: TabapayIframe;
      debit?: TabapayIframe;
    };
    tbt: {
      apiKey: string;
      revealVirtualCardApiKey: string;
      supportedCardProviders: { [K in CardBrand]: boolean };
      fontsUrl: string;
    };
    carat: {
      supportedCardProviders: { [K in CardBrand]: boolean };
    };
    userSnap: {
      spaceId: string;
      projectId: string;
    };
  };
  production: boolean;
  featureFlagProvider: {
    shouldLoad: boolean;
    clientId: string;
  };
  analytics: {
    shouldTrackEvents: boolean;
    shouldPrintEvents: boolean;
    blockedEventDestinations: EventDestination[];
    key: string;
  };
  dataDog: {
    shouldInit: boolean;
    appId: string;
    clientId: string;
    site: string;
    service: string;
  };
  redirectOnConsentScreenReject?: boolean;
  tutorialVideo: string;
  zendesk: {
    headerColor: string;
    resultListsColor: string;
    themeColor: string;
    title: string;
    logo: string;
  };
  links: LinkConstants;
  sizzersApi?: {
    baseUrl: string;
    meliomeUrl: string;
  };
  feedback: {
    triggers: {
      payment_success: FeedbackTrigger;
      first_time_invoice_creation: FeedbackTrigger;
      payment_received_marked_as_paid: FeedbackTrigger;
    };
  };
  layout: Partial<{ maxWidth: CSSProperties['maxWidth'] }>;
  requestCallback?: {
    isEnabled: boolean;
    doesRequireSubscription?: boolean; // defaults to true
    doesRequireFF?: boolean; // defaults to false
    useNewLineForPhoneDescription?: boolean; // defaults to false
    notes: { before?: RequestCallbackNotes[]; after?: RequestCallbackNotes[] };
  };
};

type TabapayIframe = {
  url: string;
  iframeWidth?: CSSObject['width'];
  iframeHeight?: CSSObject['height'];
};

export type InitOptions = {
  custom?: Record<string, unknown>;
  user?: {
    email?: string;
    userId?: string;
  };
  locale?: string;
  useSystemFonts?: boolean;
  useLocalStorage?: boolean;
};

export type FeedbackProviderProps = {
  feedbackWidgetProps?: {
    userEmail?: string;
    userId?: string;
    userCreatedAt?: string;
    organizationId?: string;
    isFeedbackWidgetOn?: boolean;
  };
  children?: React.ReactNode;
};

export type PartialMelioConfig = RecursivePartial<MelioConfig>;

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    onUserSnapLoad?: (api: SpaceApi) => Promise<void>;
    cy?: {
      Cypress: {
        testingType: string;
      };
    };
    FS?: {
      getCurrentSessionURL?: () => string | undefined;
    };
  }
}

type SettingsType = Omit<MelioConfig['settings'], 'settings'>;
type SettingsValuesTypes = SettingsType[keyof SettingsType];
type SettingsWithFlagName = Extract<SettingsValuesTypes, { flagName?: string }>;

// Union of all 'flagName' properties within MelioConfig settings
export type PartnerFeatureFlags = SettingsWithFlagName extends { flagName?: infer FlagName } ? FlagName : never;

export type SettingsPathsWithEnabledProp = PathsWithEnabled<SettingsType>;

export type MelioConfigPaths = PathsToProperty<SettingsType>;

export type SettingsKeysWithEnabledProp = {
  [K in keyof SettingsType]-?: SettingsType[K] extends { enabled?: boolean } ? K : never;
}[keyof SettingsType];
