import { useCallback, useEffect } from 'react';
import {
  MessageCallback,
  useInitPartnerBridge,
  useListenToEvent as useListenToEventUntyped,
  useSendMessage as useSendMessageUntyped,
} from '@melio/partner-bridge';

import { useRouter } from '@/hooks/router.hooks';
import { useAppRedirectData } from '@/hooks/useAppRedirect.hooks';
import { useNavigationTarget } from '@/utils/useNavigationTarget';

export const OutgoingPostMessageTypes = {
  CONTENT_SIZE_CHANGE: 'CONTENT_SIZE_CHANGE',
  USER_ACTIVE_PING: 'USER_ACTIVE_PING',
  SESSION_EXPIRED: 'SESSION_EXPIRED',
  AUTHENTICATION_ERROR: 'AUTHENTICATION_ERROR',
  NAVIGATED_TO_TARGET: 'NAVIGATED_TO_TARGET',
  MELIO_LOADED: 'MELIO_LOADED',
  PARTNER_ACTION_REQUIRED: 'PARTNER_ACTION_REQUIRED',
  LOCK_SCROLL: 'LOCK_SCROLL',
  SCROLL_TO: 'SCROLL_TO',
} as const;

export const IncomingPostMessageTypes = {
  NAVIGATE_REQUEST: 'NAVIGATE_REQUEST',
  RESIZE: 'RESIZE',
  SCROLL: 'SCROLL',
};

type OutgoingPostMessageTypeType = (typeof OutgoingPostMessageTypes)[keyof typeof OutgoingPostMessageTypes];
type IncomingPostMessageTypeType = (typeof IncomingPostMessageTypes)[keyof typeof IncomingPostMessageTypes];

type sendMessageTyped = (type: OutgoingPostMessageTypeType, payload: object) => void;
type MessageCallbackTyped<T> = (type: IncomingPostMessageTypeType, payload: T) => void;
export function frameSizeChangeHandler(sendMessage: sendMessageTyped) {
  const resizeObserver = new ResizeObserver((entries) => {
    const [entry] = entries;
    if (entry) {
      sendMessage(OutgoingPostMessageTypes.CONTENT_SIZE_CHANGE, {
        height: entry.target.clientHeight,
        width: entry.target.clientWidth,
      });
    }
  });
  resizeObserver.observe(document.body);
  return () => resizeObserver.disconnect();
}

export const useListenOnNavigation = (sendMessage: sendMessageTyped) => {
  const { target } = useNavigationTarget();

  useEffect(() => {
    if (target) {
      sendMessage(OutgoingPostMessageTypes.NAVIGATED_TO_TARGET, { target });
    }
  }, [target, sendMessage]);
};

export const useNavigateRequestHandler = () => {
  const { setAppRedirectData } = useAppRedirectData();
  const { goToAppRedirect } = useRouter();

  return useCallback(
    (_type: PostMessageTypeType, payload: { target: string }) => {
      setAppRedirectData({ target: payload.target });
      goToAppRedirect();
    },
    [setAppRedirectData, goToAppRedirect],
  );
};

const useOnLoadMessage = (sendMessage: sendMessageTyped) => {
  useEffect(() => {
    sendMessage(OutgoingPostMessageTypes.MELIO_LOADED, {
      status: 'success',
      timestamp: Date.now(),
    });
  }, [sendMessage]);
};

export function useSendMessage() {
  const sendMessage = useSendMessageUntyped();
  return sendMessage as sendMessageTyped;
}
export function usePartnerBridge() {
  useInitPartnerBridge();
  const sendMessage = useSendMessage();
  useListenOnNavigation(sendMessage);
  const navigateRequestHandler = useNavigateRequestHandler();
  useListenToEvent(IncomingPostMessageTypes.NAVIGATE_REQUEST, navigateRequestHandler);
  useEffect(() => {
    const unlisten = frameSizeChangeHandler(sendMessage);
    return unlisten;
  }, [sendMessage]);

  useOnLoadMessage(sendMessage);
}

export function useListenToEvent<T = object>(type: PostMessageTypeType, cb: MessageCallbackTyped<T>) {
  return useListenToEventUntyped(type, cb as MessageCallback);
}
