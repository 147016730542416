// eslint-disable-next-line no-restricted-imports
import setupLocatorUI from '@locator/runtime';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { getViteEnvironment } from '@/utils/error-tracking';

export const Devtools = () => {
  const environment = getViteEnvironment();
  if (environment !== 'development') return null;

  setupLocatorUI();
  return <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />;
};
