/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { debounce } from 'lodash';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { LocalStorageKeys } from '@melio/local-storage';
import { useSendMessage } from '@melio/partner-bridge';
// eslint-disable-next-line no-restricted-imports
import { ServerConfig } from '@melio/platform-api-axios-client';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useConfig } from '@melio/platform-provider';

import { refreshAccessToken } from '@/api/apiClients';
import { removePartnerTokens } from '@/api/utilities';
import { usePartnerLocalStorage } from '@/hooks/partners/usePartnerLocalStorage';
import { useRouter } from '@/hooks/router.hooks';
import { appAccessTokenSelector } from '@/store/app/app.model';
import { OutgoingPostMessageTypes, useListenToEvent } from '@/utils/partnerBridge.utils';
import { usePartnerConfig } from './partners/usePartnerConfig';
import { useLogoutPartner } from './auth.hooks';
import { useConvertLocalTimeToCorrectTime } from './useConvertLocalTimeToCorrectTime';

const INACTIVITY_INTERVAL = 1 * 60000;
const ACTIVITY_INTERVAL = 1 * 60000;

export const useResetAccessToken = () => {
  const setAccessToken = useSetRecoilState(appAccessTokenSelector);
  const localStorage = usePartnerLocalStorage();

  return (accessToken?: string, refreshToken?: string | null) => {
    const _accessToken = accessToken || localStorage.getItem(LocalStorageKeys.accessToken) || undefined;
    const _refreshToken = refreshToken || localStorage.getItem(LocalStorageKeys.refreshToken) || undefined;

    if (accessToken) {
      localStorage.setItem(LocalStorageKeys.accessToken, _accessToken || '');
    }

    if (refreshToken) {
      localStorage.setItem(LocalStorageKeys.refreshToken, _refreshToken || '');
    }

    ServerConfig.update({ accessToken: _accessToken });
    // Resetting new access token in recoil for MelioProvider
    setAccessToken(_accessToken);
  };
};

export const useClearSession = () => {
  const { partnerName } = usePartnerConfig();
  const { logout } = useLogoutPartner(partnerName);
  const localStorage = usePartnerLocalStorage();

  const setAccessToken = useSetRecoilState(appAccessTokenSelector);
  const clearSession = useCallback(async () => {
    await logout?.();
    removePartnerTokens();
    localStorage.removeItem(LocalStorageKeys.backButtonReferenceId);

    ServerConfig.update({ accessToken: undefined });
    // Resetting new access token in recoil for MelioProvider
    setAccessToken(undefined);
  }, [logout, partnerName]);

  return clearSession;
};

export const useUserActivityInterval = () => {
  const sendMessage = useSendMessage();
  const {
    settings: { expiredSessionTime },
  } = useConfig();
  useListenToEvent(OutgoingPostMessageTypes.USER_ACTIVE_PING, () => setUserLastActive(Date.now()));
  const accessToken = useRecoilValue(appAccessTokenSelector);
  const [accessTokenExpiration, setAccessTokenExpiration] = useState<number | null>(null);
  const [userLastActive, setUserLastActive] = useState<number>(Date.now());
  const [isPlatformFixRefreshAccessTokenEnabled] = useDevFeature(
    FeatureFlags.PlatformFixRefreshAccessTokenEnabled,
    false,
  );
  const { getCorrectDatetimeNow } = useConvertLocalTimeToCorrectTime();

  const registerUserInteractionEventsListeners = useCallback(() => {
    const events = ['mouseup', 'keyup'];
    const onUserInteractionEvent = debounce(() => {
      setUserLastActive(Date.now());
      sendMessage(OutgoingPostMessageTypes.USER_ACTIVE_PING, { userLastActive });
    }, 600);
    events.forEach((event) => window.addEventListener(event, onUserInteractionEvent));

    return () => events.forEach((event) => window.removeEventListener(event, onUserInteractionEvent));
  }, [setUserLastActive]);

  const refreshAccessTokenBeforeExpiration = useCallback(() => {
    if (!accessTokenExpiration) return;

    const correctDateNow = getCorrectDatetimeNow();
    const isAccessTokenExpired = correctDateNow >= accessTokenExpiration * 1000 - 120000;
    const userActivityTime = expiredSessionTime * 60000;
    const isUserActiveWithinSessionTimeout = Date.now() - userLastActive <= userActivityTime;

    if (isAccessTokenExpired && isUserActiveWithinSessionTimeout) {
      refreshAccessToken();
    }
  }, [accessTokenExpiration, userLastActive, refreshAccessToken]);

  useEffect(() => {
    if (!accessTokenExpiration) {
      return;
    }
    if (isPlatformFixRefreshAccessTokenEnabled) {
      refreshAccessTokenBeforeExpiration();
    }
  }, [refreshAccessTokenBeforeExpiration, isPlatformFixRefreshAccessTokenEnabled, accessTokenExpiration]);

  const registerActiveTokenRefreshInterval = useCallback(() => {
    if (!accessTokenExpiration) {
      return;
    }
    const interval = setInterval(() => {
      if (!accessTokenExpiration) {
        clearInterval(interval);
        return;
      }
      refreshAccessTokenBeforeExpiration();
    }, ACTIVITY_INTERVAL);

    return () => clearInterval(interval);
  }, [refreshAccessTokenBeforeExpiration, accessTokenExpiration]);

  useEffect(() => {
    if (!accessToken) {
      return;
    }
    const { exp } = jwtDecode<{ exp: number }>(accessToken);
    setAccessTokenExpiration(exp);
  }, [accessToken]);
  React.useEffect(() => registerActiveTokenRefreshInterval(), [registerActiveTokenRefreshInterval]);
  React.useEffect(() => registerUserInteractionEventsListeners(), [registerUserInteractionEventsListeners]);
};

export const useUserInactivityInterval = () => {
  const sendMessage = useSendMessage();
  const localStorage = usePartnerLocalStorage();
  const { goToSessionExpired } = useRouter();
  const clearSession = useClearSession();
  const { getCorrectDatetimeNow } = useConvertLocalTimeToCorrectTime();

  React.useEffect(() => {
    const interval = setInterval(() => {
      const refreshToken = localStorage.getItem(LocalStorageKeys.refreshToken);
      if (!refreshToken) {
        clearInterval(interval);
        return;
      }
      const { exp } = jwtDecode<{ exp: number }>(refreshToken);
      const correctDateNow = getCorrectDatetimeNow();
      if (correctDateNow >= exp * 1000) {
        clearSession();
        clearInterval(interval);
        sendMessage(OutgoingPostMessageTypes.SESSION_EXPIRED, {});
        goToSessionExpired();
      }
    }, INACTIVITY_INTERVAL);

    return () => clearInterval(interval);
  }, [clearSession]);
};
