import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { useLocation, useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useRecoilValue } from 'recoil';
import { useDynamicSettings } from '@melio/i18n-tools';
import { useBreakpoint } from '@melio/penny';
import { Analytics, useAnalyticsContext, withAnalyticsContext } from '@melio/platform-analytics';
import { PartnerName } from '@melio/platform-api';
import { MelioProvider, Theme } from '@melio/platform-provider';
import { ViewportProvider } from '@melio/platform-sdk';

import { Devtools } from '@/Devtools';
import { useFeatureFlagsInit } from '@/hooks/featureFlags';
import { usePartnerConfig } from '@/hooks/partners';
import { useFeedbackProviderProps } from '@/hooks/useFeedbackProviderProps';
import { ToastProvider } from '@/providers/Toast.provider';
import { queryClient } from '@/queries/reactQueryClient';
import { EnvironmentRibbon } from '@/ribbons/EnvironmentRibbon';
import { AppHistory } from '@/router/history.router';
import { selectedPartner } from '@/store/app/app.model';
import { getViteEnvironment } from '@/utils/error-tracking';
import { usePartnerBridge } from '@/utils/partnerBridge.utils';
import { getSessionStorage } from '@/utils/sessionStorage.utils';
import { updateFaviconAndTitleForPartner } from '@/utils/updateFaviconAndTitle';
import { getCurrentUrl } from '@/utils/url.utils';

export interface MelioConfigurationProviderProps {
  children?: React.ReactNode;
  partnerName: PartnerName;
  accessToken: string | undefined;
  onTokenExpired?: () => Promise<string | null>;
}

const AppRibbons = () => {
  return <EnvironmentRibbon />;
};

export const NavigateSetter = () => {
  AppHistory.navigate = useNavigate();
  return null;
};

type ConfigurationProviderProps = {
  partnerName: PartnerName;
  accessToken: string | undefined;
  onTokenExpired: () => Promise<string | null>;
  children: React.ReactNode;
  disablePermissions?: boolean;
};

export const ConfigurationProvider = withAnalyticsContext(
  ({ partnerName, accessToken, onTokenExpired, disablePermissions, children }: ConfigurationProviderProps) => {
    const environment = getViteEnvironment();
    const { isExtraSmallScreen } = useBreakpoint();
    const { partnerConfig, partnerGroup } = usePartnerConfig(partnerName);

    useAnalyticsContext({
      globalProperties: {
        PartnerName: partnerConfig.partnerNameAnalyticsEvents,
        PartnerGroup: partnerGroup,
      },
    });

    React.useEffect(() => {
      const { key, shouldPrintEvents, shouldTrackEvents, blockedEventDestinations } = partnerConfig.config.analytics;
      const isCypressE2E = window.cy?.Cypress?.testingType === 'e2e';
      Analytics.init({
        printEvents: shouldPrintEvents,
        reportEvents: shouldTrackEvents && !isCypressE2E,
        blockedEventDestinations,
        segmentKey: key,
      });

      updateFaviconAndTitleForPartner({
        tabTitle: partnerConfig.config.tabTitle,
        faviconHref: partnerConfig.icons.Favicon,
      });
    }, [partnerConfig]);

    usePartnerBridge();
    useFeatureFlagsInit(accessToken);

    const location = useLocation();
    const { breakpoint } = useBreakpoint();
    useAnalyticsContext({ globalProperties: { pathname: location.pathname, DeviceBreakpoint: breakpoint } });

    const selectedPartnerRecoilValue = useRecoilValue(selectedPartner);

    const dynamicSettings = useDynamicSettings(getSessionStorage(), getCurrentUrl());
    const [feedbackProps] = useFeedbackProviderProps();

    return partnerConfig && selectedPartnerRecoilValue ? (
      <MelioProvider
        theme={partnerConfig.theme as Theme}
        config={partnerConfig.config}
        translationsSrc={partnerConfig.config.translationsSrc}
        dynamicSettings={dynamicSettings}
        accessToken={accessToken}
        onTokenExpired={onTokenExpired}
        feedbackWidgetProps={feedbackProps}
        partnerName={partnerName}
        permissionsEnabled={!disablePermissions}
        queryClient={queryClient}
      >
        <ViewportProvider>
          <AppRibbons />
          <NavigateSetter />
          <GoogleOAuthProvider clientId={`${partnerConfig.config.services.googleClientId}`}>
            <ToastProvider>{children}</ToastProvider>
          </GoogleOAuthProvider>
          {environment === 'development' && !isExtraSmallScreen && <Devtools />}
        </ViewportProvider>
      </MelioProvider>
    ) : (
      <></>
    );
  },
);
