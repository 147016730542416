/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { merge } from 'lodash';
import { useRecoilValue } from 'recoil';
import { partnerGroupParser } from '@melio/partner-tools';
import { PartnerName } from '@melio/platform-api';
import { Logger } from '@melio/platform-logger';
import { RecursivePartial } from '@melio/platform-provider';

import { PartnersConfiguration } from '@/partnersConfig';
import { PartnerConfig } from '@/partnersConfig.types';
import { selectedPartner } from '@/store/app/app.model';
import { getSettingsOverrides, usePartnerConfigOverrides } from '@/utils/getOverrideParams.utils';
let logged = false;
function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

function deepEqual(value1, value2) {
  // Check for strict equality
  if (value1 === value2) {
    return true;
  }

  // Treat `undefined` and missing keys as equivalent
  if (value1 === undefined && value2 === undefined) {
    return true;
  }

  // Explicitly handle `null`
  if (value1 === null && value2 === null) {
    return true;
  }

  // Handle objects and arrays
  if (typeof value1 === 'object' && value1 !== null && typeof value2 === 'object' && value2 !== null) {
    const keys1 = Object.keys(value1);
    const keys2 = Object.keys(value2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (!deepEqual(value1[key], value2[key])) {
        return false;
      }
    }

    return true;
  }

  // For other types, treat as unequal
  return false;
}

function findDifferences(obj1, obj2, path = '') {
  const differences = [];

  const buildPath = (key) => (path ? `${path}.${key}` : key);

  const allKeys = new Set([...Object.keys(obj1 || {}), ...Object.keys(obj2 || {})]);

  for (const key of allKeys) {
    const newPath = buildPath(key);

    const value1 = key in obj1 ? obj1[key] : undefined;
    const value2 = key in obj2 ? obj2[key] : undefined;

    // If both values are undefined or null, treat them as equal
    if ((value1 === undefined && value2 === undefined) || (value1 === null && value2 === null)) {
      continue;
    }

    // If both values are objects, recurse
    if (typeof value1 === 'object' && typeof value2 === 'object' && value1 !== null && value2 !== null) {
      differences.push(...findDifferences(value1, value2, newPath));
    } else if (!deepEqual(value1, value2)) {
      differences.push(`Changed: ${newPath} from ${JSON.stringify(value1)} to ${JSON.stringify(value2)}`);
    }
  }

  return differences;
}

export const usePartnerConfig = (partner?: PartnerName) => {
  const selectedPartnerRecoilValue = useRecoilValue(selectedPartner);

  const currentPartner = partner || (selectedPartnerRecoilValue as PartnerName);
  if (!currentPartner) {
    throw Error('Current partner could not be detected');
  }

  const { partnerGroup } = partnerGroupParser(currentPartner);
  const configOverrides = usePartnerConfigOverrides(currentPartner);
  const { settingsOverrides, settingsOverridesString } = getSettingsOverrides();
  const partnerConfig = React.useMemo<PartnerConfig>(() => {
    const currentPartnerConfig = PartnersConfiguration[currentPartner!];
    const overrides: RecursivePartial<PartnerConfig> = {
      ...configOverrides,
      config: {
        settings: {
          partnerDisplayName: currentPartnerConfig.displayName,
          partnerProductName: currentPartnerConfig.partnerProductName,
          ...settingsOverrides,
        },
      },
    };

    const mergedConfig = merge({}, currentPartnerConfig, overrides);
    const injectedMergedConfig = merge({}, window.config, overrides);
    const diff = findDifferences(mergedConfig, injectedMergedConfig);
    if (diff.length > 0 && !logged) {
      Logger.log(
        `server-client-configs-unequal. 
        
        Expected: ${JSON.stringify(currentPartnerConfig)} 
        
        Received: ${JSON.stringify(window.config)}

        Script: ${document.getElementById('app-init')?.innerHTML || 'No script found'}
        
        `,
        'warn',
      );
      logged = true;
    }
    return { ...merge(currentPartnerConfig, overrides) };
  }, [currentPartner, settingsOverridesString, configOverrides]);

  useEffect(() => {
    if (partnerConfig?.config?.settings?.isEmbeddedExperience) {
      if (partnerConfig?.config?.settings?.embeddedExperienceInsideScroll) {
        document.body.classList.add('embedded-experience-inside-scroll');
      } else {
        document.body.classList.add('embedded-experience-parent-scroll');
      }
    } else {
      document.body.classList.remove('embedded-experience-inside-scroll');
      document.body.classList.remove('embedded-experience-parent-scroll');
    }

    if (inIframe()) {
      document.body.classList.add('in-iframe');
    } else {
      document.body.classList.remove('in-iframe');
    }

    // HOTFIX: when moving from uploading a file to the Fiserv combined pay experience, this hook is
    // unnmounted, and the necessary css classes are removed. This is a temporary fix to ensure the
    // iFrame experience does not break. This will suffice until we can determine why the hook is unmounting.

    // return () => {
    //   document.body.classList.remove('embedded-experience-inside-scroll');
    //   document.body.classList.remove('embedded-experience-parent-scroll');
    //   document.body.classList.remove('in-iframe');
    // };
  }, [partnerConfig?.config?.settings?.isEmbeddedExperience]);

  return { partnerName: currentPartner!, partnerGroup, partnerConfig };
};
