/*
This list contains feature flags intended for development and testing purposes only.
For partner specific feature flags, please add them to the partner configuration under the "settings" section.
Please use PascalCase for the new enum names, according to the official typescript documentation page.
Link: https://www.typescriptlang.org/docs/handbook/enums.html
*/
export enum FeatureFlags {
  MultiOrgUI = 'platform-multi-org-ui',
  AccountManagerAccessCollaborators = 'am-access-collaborators-list',
  BatchPayments = 'channels-batch-payments',
  BatchPaymentRequestsEnabled = 'batch-payment-requests-enabled',
  CombinedPayments = 'channels-combined-payments',
  JustPay = 'channels-enable-just-pay',
  ExportPaymentsMultipleFormats = 'exoport-payments-multiple-formats',
  NotificationPreferences = 'platform-notification-preferences',
  FeedbackWidget = 'feedback-widget-platform',
  RecurringPayments = 'recurring-payments-platform',
  SyncNow = 'platform-sync-now',
  NpeFirstTimeExperience = 'platform-npe-fte',
  IsInternationalDeliveryMethodSupported = 'platform-international-payments-web',
  IsConvertToCorrectTimeAuthEnabled = 'is-convert-to-correct-time-auth-enabled',
  SplitContactsTab = 'split-contacts-tab',
  MarkAsPaid = 'platform-mark-as-paid',
  PayUnpaidBills = 'enable-pay-unpaid-bills',
  TimelineTrackerEnabled = 'platform-timeline-tracker-enabled',
  NetworkVendorSupport = 'platform-network-vendor-support',
  DisplayVendorAsFreeTextInstantBill = 'display-vendor-as-free-text-instant-bill',
  PartialPaymentsEnabled = 'platform-partial-payments',
  DisplayReceivingMethodsItem = 'display-platform-settings-receiving-methods',
  SwitchToZendeskChat = 'switch-to-zendesk-chat',
  CheckFeesPromotionEnabled = 'partnerships-free-checks',
  RequestADemo = 'request-a-demo',
  CustomCheckAddressEnabled = 'platform-custom-check-address-enabled',
  UnilateralPaperCheckAddressFlow = 'unilateral-platform-papercheck-address-flow',
  CapOnePortalBackToL3 = 'capone-portal-back-to-l3',
  isCapOnePortalEnabled = 'is-capone-portal-enabled',
  ShouldIndustryBePartOfLegalInfo = 'should-industry-be-part-of-legal-info',
  RobinhoodRefundFlow = 'partnerships-robinhood-refund-flow',
  IsInternationalSupportedForRecurringFlow = 'platform-international-payments-recurring',
  ARDashboard = 'ar-dashboard',
  PlatformFinancing = 'platform-financing',
  EnableUpsellInPlatformUI = 'enable-upsell-in-platform-ui',
  IsSpendManagementEnabled = 'is-spend-management-enabled',
  IsPayDashboardTourEnabled = 'Platform-enable-pay-dashboard-tour',
  IsDeliveryDateTourEnabled = 'platform-enable-delivery-date-tour',
  IsFiservDashboardTourEnabled = 'platform-enable-fiserv-dashboard-tour',
  ApprovalWorkflows = 'platform-approval-workflows',
  PlatformMultipleBillsUpload = 'platform-multiple-bills-upload',
  IsVirtualCardSupported = 'platform-virtual-card',
  IsSearchBusinessesInDirectoriesSupported = 'is-search-businesses-in-directories-supported',
  CloverFreeAchPromotion = 'clover-free-ach-promotion',
  AddCompanyEnabled = 'platform-multi-org-web-add-company',
  UseOrganizationsEndpointForCompaniesList = 'platform-use-organizations-endpoint-for-companies-list',
  FetchPaymentActionsOnDashboard = 'fetch-payment-actions-on-dashboard',
  RtpPayorEnabledForBatchPayments = 'rtp-payor-batch-payments',
  FTEChangesForOrgsConnectedToAccountingPlatform = 'platform-fte-changes-for-connected-to-accounting-orgs',
  qboUpcomingBillsReminderPartnerships = 'qbo-upcoming-bills-reminder-partnerships',
  BillingFees = 'platform-billing-fees',
  EBills = 'platform-ebills',
  AddDeliveryDateToCreateAndUpdatePayment = 'add-delivery-date-to-create-and-update-payment',
  IsEngagementProvider = 'is-engagement-provider',
  MessagingAccountCentricViewCase = 'messaging-account-centric-view-case',
  PlatformXeroAccountingSoftwareEnabled = 'platform-xero-accounting-software-enabled',
  PlatformFinancingApplicationFlow = 'platform-financing-application-flow',
  PlatformFinancingApplicationFlowUBO = 'platform-financing-application-flow-beneficial-owners',
  PlatformFinancingApplicationFlowFromPayment = 'platform-financing-application-flow-from-payment',
  EditApprovalWorkflowEnabled = 'platform-approval-workflows-edit',
  NewDashboardSchedulePaymentCTA = 'platform-app.ap.new-dashboard-schedule-payment-cta',
  AutoPay = 'platform-auto-pay',
  NpeSort = 'platform-npe-sort',
  PlatformScheduledPaymentAccountingPlatformSyncPromotion = 'platform-scheduled-payment-account-platform-sync-promotion',
  SbbExclusiveUserView = 'platform-app-sbb-exclusive-user-view',
  NpeSortMobile = 'platform-npe-sort-mobile',
  NPEPaymentFees = 'platform-npe-payment-fees',
  PlatformVendorNameConstraints = 'platform-vendor-name-constraints',
  ShowPaymentScheduledDisclaimer = 'platform-show-payment-scheduled-disclaimer',
  InAppMarketingEnabled = 'in-app-marketing-enabled',
  BatchDeleteBills = 'platform-batch-delete-bills',
  UnderMaintenance = 'under-maintenance',
  PlatformVendorsBatchNewPaymentsEnabled = 'platform-vendors-batch-new-payments',
  PlatformFixRefreshAccessTokenEnabled = 'platform-fix-refresh-access-token',
  GetBillFileOcrDataOnBillDetailsScreen = 'get-bill-file-ocr-data-on-bill-details-screen',
  PlatformApprovalWorkflowsV2 = 'platform-approval-workflows-v2',
  CancelPayment = 'platform-cancel-payment',
  MtlKycUplift = 'platform-mtl-kyc-uplift',
  ExistingUserMtlKycUplift = 'platform-existing-user-mtl-kyc-uplift',
  RiskMtlStageAndInitiatorParams = 'risk-mtl-stage-and-initiator-params',
  PlatformBillClassesQbo = 'platform-bill-classes-qbo',
  EBillsAmazonEnabled = 'ebills-amazon-enabled',
  EBillsGmailEnabled = 'ebills-gmail-enabled',
  PlatformBillLocations = 'platform-bill-locations',
  GrowthAddCollaboratorsEnabled = 'growth-add-collaborator-enabled',
  GrowthAddPaymentMethodEnabled = 'growth-add-payment-method-enabled',
  ExternalVendorLogoEnabled = 'platform-show-external-vendor-logo',
  NewPaymentScheduledSuccessfully = 'new-payment-scheduled-successfully',
  GrowthRecommendedVendorsExperiment = 'growth-recommended-vendors-experiment',
  GrowthExperimentOnboardingPaywallXRemoval = 'smb-growth-experiment-onboarding-paywall-x-removal',
  PlatformFinancingPaymentFlow = 'platform-financing-payment-flow',
  ContextualOnboardingGmailSyncEnabled = 'platform-contextual-onboarding-gmail-sync-enabled',
  PlatformSyncMigrationProgressIndication = 'platform-full-sync-progress-indication',
  W9TaxPayerInfo = 'w-9-taxpayer-information',
  W9CollectFromAllVendors = 'w-9-collect-from-all-vendors',
  FinancingTabToolTipEnabled = 'financing-tab-tooltip-enabled',
  PlatformScheduledPaymentCardToCardPromotionEnabled = 'platform-scheduled-payment-card-to-card-promotion',
  SetAsDefaultPaymentMethodEnabled = 'set-a-default-payment-method-enabled',
  PlatformUserManagementAdjustmentsFiserv = 'platform-user-management-adjustments-fiserv',
  SecureFileUpload = 'secure-file-upload',
  PrefillVendorOcrDeliveryMethod = 'prefill-ocr-vendor-delivery-method',
  Todos = 'todos',
  TodosNewIndications = 'todos-new-indications',
  PaymentDrawerPrintButton = 'payment-drawer-print-button',
  FiservNewEditVendorExperience = 'fiserv-new-edit-vendor-experience',
  PlatformA11yAutoCloseToastOnNavigation = 'platform-a11y-auto-close-toast-on-navigation',
  OptimizeAccountingSoftwareDataFetching = 'optimize-accounting-software-data-fetching',
  PlaidMicroDeposits = 'plaid-micro-deposits',
  PlaidMicroDepositsGetPaidEnabled = 'plaid-micro-deposits-get-paid',
  NewFileUploadEnabled = 'platform-new-file-upload-enabled',
  PlatformGoogleSSO = 'platform-google-sso',
  IsVendorPaymentTrackingFeatureOpen = 'is-vendor-payment-tracking-feature-open',
  LoadOnboardingSavedState = 'load-onboarding-saved-state',
  MelioMigrationWhitelistToBlacklist = 'melio-migration-whitelist-to-blacklist',
  ApproveLatePayment = 'approve-late-payment',
  NPEOverride = 'npe-override-enabled',
  CollectMissingVendorInfoEnabled = 'collect-missing-vendor-info-enabled',
  BatchPaymentEbillEditAmount = 'batch-payment-ebill-edit-amount',
  BatchPaymentsIndicationForFailedPaymentsSchedulingEnabled = 'batch-payments-indication-for-failed-payments-scheduling-enabled',
  BatchPaymentsShowPaymentIntentsMissingDetailsIndicationAsCritical = 'batch-payments-show-payments-intents-missing-details-indication-as-critical',
  BatchPaymentsNewFundingSourceSelect = 'batch-payments-new-funding-source-select',
  IsLineItemsEnabledOnMobile = 'is-line-items-enabled-on-mobile',
  GuestPayorFlowNavigateApUsersToPayDashboard = 'guest-payor-fulfillment-flow-navigate-ap-users-to-dashboard',
  isUpsellOfferBannerEnabled = 'is-upsell-offer-banner-enabled',
  ApToastToBannerMigration = 'ap-toasts-to-banner-migration',
  ARSettingsInvoicePreferences = 'ar-settings-invoice-preferences',
  AROnboarding = 'ar-onboarding',
  PlatformBatchPayments = 'platform-batch-payments',
  IsAccountingSoftwareEnabled = 'is-accounting-software-enabled',
  editInitiallyUnmanagedAndNowMangedVendor = 'edit-initially-unmanaged-vendor',
  IsArProcessingFeeEnabled = 'is-ar-processing-fee-enabled',
  ZenworkW9Form = 'platform-feature-zenwork-w-9-form',
  SubscriptionsPaymentFeeFailedGracePeriod = 'platform-subscriptions-payment-fee-failed-grace-period',
  ArMtlKycUplift = 'ar-mtl-kyc-uplift',
  EnableInternationalUSDWise = 'enable-international-usd-wise',
  ARInvoiceSummaryFooter = 'ar-invoice-summary-footer',
  AllowCaratIntegration = 'platform-carat-integration',
  EnableTokenizedCardDetailsOnSuvcAcceptanceFlow = 'enable-tokenized-card-details-on-suvc-acceptance-flow',
  ARPaymentsTab = 'ar-payments-tab',
  AccountLimitationsV2Enabled = 'account-limitations-v2-enabled',
  IsOrgOwesUsMoneyBannerEnabled = 'is-org-owes-us-money-enabled',
  DisplayCancelPaymentsBannerInCancelFlowForClients = 'display-cancel-payments-banner-in-cancel-flow-for-clients',
  UboResidencyNewFields = 'ubo-residency-new-fields',
  Welcome15ForOrgWhoNeverStartedFreeTrial = 'welcome-15-for-orgs-who-never-started-a-free-trial',
  DuplicateVendors = 'sync-deactivated-for-register',
  GuestPaymentIntentInPaymentFulfillmentFlow = 'use-guest-payment-intent-in-payment-fulfillment-flow',
  // Hi! Thinking about adding a new feature flag here?
  // Please read this first: https://meliopayments.slack.com/archives/C02UGS77BLL/p1739188401318179
}
