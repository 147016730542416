import { matchRoutes, useLocation } from 'react-router-dom';

import { TARGET_ACTION_MAPPINGS } from './target.utils';

export const useNavigationTarget = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const matchedRoute = matchRoutes(
    TARGET_ACTION_MAPPINGS.map(({ path }) => ({ path })),
    location.pathname,
  );

  if (!matchedRoute || matchedRoute.length === 0) {
    return { target: null };
  }

  const match = matchedRoute[0];
  const params = match.params || {};

  const mapping = TARGET_ACTION_MAPPINGS.find((m) => m.path === match.route.path);

  const extractedParams = { ...params };

  if (mapping.idInQueryParam) {
    extractedParams.id = searchParams.get(mapping.pathQueryParamKey);
  }

  const finalTarget = mapping.constructTarget(extractedParams);

  return { target: finalTarget };
};
