import { ExternalLayout } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import {
  BankAccountDeliveryMethod,
  DeliveryMethodType,
  PaperCheckDeliveryMethod,
  useUnilateralOrderedDeliveryMethodTypeOptions,
  useVexMyAccount,
  VexGuestPaymentResponse as PaymentData,
  VirtualCardAccountDeliveryMethod,
} from '@melio/platform-api';
import { DeliveryMethodSelectionByPayeeHeaderWidget, HeaderAuthSection } from '@melio/vex-widgets';
import { FC, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { WithActivityConfig } from '../../contexts/ActivityConfig';
import { useNavigateToEmailVerification } from '../../hooks/useNavigateToEmailVerification';
import { usePathInVexRouter } from '../../hooks/usePathInVexRouter';
import { usePaymentData } from '../../hooks/usePaymentData.hooks';
import { PaymentCanceledScreen } from '../../shared/screens/payment-canceled';
import { ServerErrorScreen } from '../../shared/screens/server-error';
import { AcceptPaymentWithBankAccountDeliveryMethodParams } from '../../types';
import { AcceptPaymentWithBankAccountActivity } from '../accept-payment-with-bank-account';
import { AcceptPaymentWithCheckActivity } from '../accept-payment-with-check/AcceptPaymentWithCheck.activity';
import { AcceptPaymentWithVirtualCardActivity } from '../accept-payment-with-virtual-card';
import { useCreateDeliveryMethod } from '../add-bank-account/hooks/useCreateDeliveryMethod';
import { useAcceptPaymentSuccessLabels } from './hooks/useAcceptPaymentSuccessLabels.hooks';
import { AcceptPaymentSuccessScreen } from './screens/accept-payment-success/AcceptPaymentSuccess.screen';
import { AlreadyAcceptedScreen } from './screens/AlreadyAccepted';
import { DeliveryMethodSelectionScreen } from './screens/DeliveryMethodSelection';
import { createActivityConfig, UnilateralConfigContext, useActivityContext } from './Unilateral.config';

type PaymentDetailsHeaderProps = Pick<PaymentData, 'orgName' | 'note' | 'amount' | 'invoiceNumber'> & {
  showAvatar: boolean;
};

type UnilateralWithMsnEnrollmentActivityProps = { paymentId: string };

export const PaymentDetailsHeader: FC<PaymentDetailsHeaderProps> = ({
  orgName,
  note,
  amount,
  invoiceNumber,
  showAvatar,
}) => (
  <DeliveryMethodSelectionByPayeeHeaderWidget
    showAvatar={showAvatar}
    accountName={orgName as unknown as string}
    paymentNote={note as unknown as string}
    paymentAmount={amount}
    paymentInvoiceNumber={invoiceNumber}
  />
);

type SupportedDeliveryMethod = BankAccountDeliveryMethod | PaperCheckDeliveryMethod | VirtualCardAccountDeliveryMethod;

const Activity: React.VFC<UnilateralWithMsnEnrollmentActivityProps> = ({ paymentId }) => {
  const { showLoginHeaderSection, shouldSuggestJoinMelio } = useActivityContext();
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const { data: accountData, isFetched: isAccountDataFetched, error: accountError, isGuest } = useVexMyAccount();
  const isUserLoggedIn = !isGuest;

  const {
    data: paymentData,
    error: paymentError,
    isLoading: isPaymentLoading,
    isFetched: isPaymentFetched,
    refetchPayment,
  } = usePaymentData({ paymentId, isGuest }, { enabled: isAccountDataFetched });

  const {
    data: orderedDeliveryMethodTypeOptions,
    isLoading: isLoadingDeliveryMethodTypeOptions,
    error: deliveryMethodTypeOptionsError,
  } = useUnilateralOrderedDeliveryMethodTypeOptions({ paymentId });

  const acceptPaymentSuccessLabels = useAcceptPaymentSuccessLabels({
    paymentAmount: paymentData?.amount,
    payorName: paymentData?.orgName,
    paymentInvoiceNumber: paymentData?.invoiceNumber ?? undefined,
    deliveryMethod: paymentData?.deliveryMethod as SupportedDeliveryMethod | undefined,
  });

  const isFetchError = !!paymentError || (!!accountError && !isGuest) || !!deliveryMethodTypeOptionsError;

  const { createBankAccountDeliveryMethod, copyDeliveryMethodOwnedToLocalVendor } = useCreateDeliveryMethod({
    isGuest,
  });
  const onPaymentAccepted = async () => {
    await refetchPayment();
    navigate('success');
  };

  const acceptPaymentWithBankAccountDeliveryMethod = async ({
    bankAccountData,
    existingDeliveryMethodId,
  }: AcceptPaymentWithBankAccountDeliveryMethodParams) => {
    let deliveryMethodId: string | undefined;
    const analyticsParams = {
      Cta: 'verify-and-continue',
      Intent: 'payment-accepted',
      DeliveryMethodId: deliveryMethodId,
    };

    try {
      if (existingDeliveryMethodId !== undefined) {
        ({ deliveryMethodId } = await copyDeliveryMethodOwnedToLocalVendor(existingDeliveryMethodId));
      } else {
        ({ deliveryMethodId } = await createBankAccountDeliveryMethod(bankAccountData));
      }
      track('Vendor', 'Status', { ...analyticsParams, DeliveryMethod: deliveryMethodId });
    } catch (error) {
      track('Vendor', 'Status', {
        ...analyticsParams,
        StatusType: 'Failure',
        DeliveryMethod: existingDeliveryMethodId,
      });
      throw error;
    }

    await refetchPayment();
    navigate('success');
  };

  const unilateralPath = usePathInVexRouter({ pathInVexRouter: `unilateral/${paymentId}` });

  const { navigateToEmailVerification } = useNavigateToEmailVerification();

  const onDMSelection = (dmType: DeliveryMethodType) => {
    track('DeliveryMethod', 'Click', {
      DeliveryMethodType: dmType,
      PageName: 'choose-how-to-get-paid',
      Intent: 'choose-delivery-method-type',
      Cta: dmType,
    });
    if (dmType === DeliveryMethodType.PaperCheck) {
      navigate('paper-check');
    } else if (dmType === DeliveryMethodType.BankAccount) {
      navigate('bank-account');
    } else if (dmType === DeliveryMethodType.VirtualCard) {
      navigate('virtual-card');
    }
  };

  useEffect(() => {
    if (isPaymentFetched && paymentData?.deliveryMethod?.type !== DeliveryMethodType.VirtualAccount) {
      navigate('already-accepted');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaymentFetched]);

  if (isPaymentLoading || !isAccountDataFetched || isLoadingDeliveryMethodTypeOptions) {
    return <ExternalLayout isLoading />;
  }

  if (isFetchError || !paymentData || !orderedDeliveryMethodTypeOptions) {
    return <ServerErrorScreen />;
  }

  if (paymentData.isCanceled) {
    return <PaymentCanceledScreen accountName={paymentData.orgName} accountEmail={paymentData.creatorAccountEmail} />;
  }

  const userName = accountData?.user ? `${accountData.user.firstName} ${accountData.user.lastName}` : undefined;

  const onLoginClick = () => {
    track('DeliveryMethod', 'Click', {
      Cta: 'log-in',
    });
    navigateToEmailVerification({
      utm_campaign: 'unilateral',
      utm_medium: 'unilateral-payment',
    });
  };

  const header = showLoginHeaderSection ? (
    <HeaderAuthSection onLoginButtonClick={onLoginClick} loggedInUserName={userName} />
  ) : undefined;

  return (
    <Routes>
      <Route
        index
        element={
          <DeliveryMethodSelectionScreen
            onDMselection={onDMSelection}
            contentHeaderSection={<PaymentDetailsHeader {...paymentData} showAvatar />}
            header={header}
            orderedDeliveryMethodTypeOptions={orderedDeliveryMethodTypeOptions}
          />
        }
      />
      <Route
        path="paper-check/*"
        element={
          <AcceptPaymentWithCheckActivity
            paymentId={paymentId}
            onDone={onPaymentAccepted}
            onBack={() => navigate(-1)}
          />
        }
      />
      <Route
        path="bank-account/*"
        element={
          <AcceptPaymentWithBankAccountActivity
            onAcceptPayment={acceptPaymentWithBankAccountDeliveryMethod}
            contentHeaderTitle={<PaymentDetailsHeader {...paymentData} showAvatar={false} />}
            payorOrganizationName={paymentData.orgName}
            paymentId={paymentId}
            cancelUrlFallback={unilateralPath}
            header={header}
          />
        }
      />
      <Route
        path="virtual-card/*"
        element={
          <AcceptPaymentWithVirtualCardActivity
            paymentId={paymentId}
            paymentData={paymentData}
            paymentError={paymentError}
            isLoading={isPaymentLoading}
            contentHeaderTitle={<PaymentDetailsHeader {...paymentData} showAvatar={false} />}
            onDone={onPaymentAccepted}
            onBack={() => navigate(-1)}
          />
        }
      />
      <Route
        path="success"
        element={
          <AcceptPaymentSuccessScreen
            paymentEstimatedDelivery={paymentData.deliveryEta as Date}
            paymentMaxEstimatedDelivery={paymentData.maxDeliveryEta as Date}
            deliveryMethod={paymentData.deliveryMethod as SupportedDeliveryMethod}
            showJoinMelioFooter={shouldSuggestJoinMelio && !isUserLoggedIn}
            externalLayoutHeader={isUserLoggedIn ? header : undefined}
            headerTitle={acceptPaymentSuccessLabels?.title}
            headerDescription={acceptPaymentSuccessLabels?.description}
          />
        }
      />
      <Route
        path="already-accepted"
        element={
          <AlreadyAcceptedScreen payorOrganizationName={paymentData.orgName} paymentAmount={paymentData.amount} />
        }
      />
    </Routes>
  );
};

export const UnilateralActivity: React.VFC<UnilateralWithMsnEnrollmentActivityProps> = (props) => (
  <WithActivityConfig Context={UnilateralConfigContext} createConfig={createActivityConfig}>
    <Activity {...props} />
  </WithActivityConfig>
);
