import { partnerBridgeService } from '@melio/partner-bridge/src/PartnerBridgeService';

import { OutgoingPostMessageTypes } from '@/utils/partnerBridge.utils';
import { encodeRedirectToken } from '@/utils/target.utils';

type ErrorData = {
  errorCode: string;
  requiredAction?: string;
  rawData?: unknown;
};

export function handleError(error: unknown) {
  const typedError = error as { message: string; data: ErrorData[] };
  const errorData = typedError.data?.[0];

  if (errorData?.requiredAction) {
    partnerBridgeService.sendMessage(OutgoingPostMessageTypes.PARTNER_ACTION_REQUIRED, {
      requiredAction: errorData.requiredAction,
      rawData: errorData.rawData,
      returnUrl: getReturnUrl(),
    });
  }
}

function getReturnUrl() {
  const currentUrl = new URL(window.location.href);
  return encodeRedirectToken({
    path: currentUrl.pathname,
    query: currentUrl.search,
  });
}
