import { useListenToEvent } from '@melio/partner-bridge';
import React, { useMemo, useRef } from 'react';

export type ViewportState = {
  rect: {
    height: number;
    width: number;
    x: number;
    y: number;
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
  viewportHeight: number;
  viewportWidth: number;
  scrollY: number;
  scrollX: number;
};

type ResizePayload = ViewportState;

type ViewportDataAPI = {
  getBoundingClientRect(): ViewportState['rect'];
  getScrollY(): number;
  getViewportHeight(): number;
};
const ViewportDataContext = React.createContext<ViewportDataAPI | null>(null);

const DefaultViewportData: ViewportState = {
  rect: document.body.getBoundingClientRect(),
  viewportHeight: window.innerHeight,
  viewportWidth: window.innerWidth,
  scrollY: window.scrollY,
  scrollX: window.scrollX,
};

export function ViewportProvider({ children }: { children: React.ReactNode }) {
  const viewportState = useRef<ViewportState>(DefaultViewportData);

  useListenToEvent('RESIZE', (_, data: ResizePayload) => {
    viewportState.current = data;
  });
  useListenToEvent<{ scrollX: number; scrollY: number }>('SCROLL', (_, data) => {
    viewportState.current.scrollX = data.scrollX;
    viewportState.current.scrollY = data.scrollY;
  });

  const api = useMemo(
    () => ({
      getBoundingClientRect(): ViewportState['rect'] {
        return viewportState.current.rect || document.body.getBoundingClientRect();
      },
      getScrollY(): number {
        return viewportState.current.scrollY || window.scrollY;
      },
      getViewportHeight(): number {
        return viewportState.current.viewportHeight || window.innerHeight;
      },
    }),
    []
  );

  return <ViewportDataContext.Provider value={api}>{children}</ViewportDataContext.Provider>;
}

export function useViewport() {
  const context = React.useContext(ViewportDataContext);
  if (!context) {
    throw new Error('useViewportData must be used within a ViewportDataProvider');
  }
  return context;
}
