import { IconKey } from '@melio/penny';
import { BankAccount, DeliveryMethod, DeliveryMethodType, formatAddress } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

export const useGetDeliveryMethodLabel = (deliveryMethodType: DeliveryMethodType) => {
  const { formatMessage } = useMelioIntl();

  switch (deliveryMethodType) {
    case 'bank-account':
      return formatMessage('widgets.deliveryMethodListItemByPayee.bankAccount.label');

    case 'paper-check':
      return formatMessage('widgets.deliveryMethodListItemByPayee.paperCheck.label');

    case 'virtual-card':
      return formatMessage('widgets.deliveryMethodListItemByPayee.virtualCard.label');

    default:
      return formatMessage('widgets.deliveryMethodListItemByPayee.default.label', { deliveryMethodType });
  }
};

export const useGetDeliveryMethodDescription = (deliveryMethod: DeliveryMethod): string => {
  const { formatMessage } = useMelioIntl();

  switch (deliveryMethod.type) {
    case 'bank-account':
      return formatMessage('widgets.deliveryMethodListItemByPayee.bankAccount.description', {
        account: deliveryMethod.details.accountNumberLast4Digits,
      });

    case 'paper-check':
      return formatMessage('widgets.deliveryMethodListItemByPayee.paperCheck.description', {
        address: formatAddress(deliveryMethod.details.address),
      });

    case 'virtual-card':
      return formatMessage('widgets.deliveryMethodListItemByPayee.virtualCard.description', {
        emailAddress: deliveryMethod.details.accountEmail,
      });

    default:
      return '';
  }
};

export const getDeliveryMethodIconKey = (deliveryMethodType: DeliveryMethodType): IconKey => {
  switch (deliveryMethodType) {
    case 'bank-account':
      return 'bank';
    case 'paper-check':
      return 'paper-check';
    case 'virtual-card':
      return 'credit-card';
    default:
      return 'bank';
  }
};

export const extractBankAccountNumberLastFourDigits = (bankAccountDetails: BankAccount | undefined) => {
  const bankAccountNumberLastFourDigits = bankAccountDetails?.accountNumber?.slice(-4);

  return bankAccountNumberLastFourDigits;
};
